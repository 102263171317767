
import '../sass/index.scss';

let sp_falg;
let pc_falg;

const $nav = $("nav");
const $nav_toggle = $(".nav__toggle");
const pageID = $("body").attr("id");
const $header = $("header .container");
let scrolled_top;

const navInit = function () {
    if ($nav.length <= 0) return;

    $nav.find("." + pageID).addClass("is-active");

    $nav_toggle.on("click", function () {
        changeGlobalNav();
    });

};

const changeGlobalNav = function () {
    if ($nav_toggle.hasClass("on")) {
        hideGlobalNav(scrolled_top);
    } else {
        showGlobalNav();
    }
};

const showGlobalNav = function () {

    $nav_toggle.addClass("on");

    scrolled_top = $(window).scrollTop();
    $header.slideDown(function onConplete() {
        $('body').css({ 'position': 'fixed', 'top': 0 });
    });

};

const hideGlobalNav = function (position) {

    $('body').css({ 'position': '', 'top': '' })
    $(window).scrollTop(position);
    $nav_toggle.removeClass("on");
    $header.slideUp();
};

function anchorScroll() {
    $("a.smooth").on("click", function () {

        var adjust = 0; //for scroll event or header
        if (pc_falg) {
            adjust = $('header').outerHeight();
        }

        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? "html" : href);
        var position = target.offset().top - adjust;
        var distance = Math.abs(position - $(window).scrollTop());

        var k = $(this).attr("data-k");
        if (k != undefined) {
            position += parseInt(k);
        }

        var speed =
            Math.min(700, Math.max(300, Math.floor(distance * 0.5))) / 1000;
        gsap.to($("body,html"), speed, {
            scrollTop: position,
            ease: "powor2.easeOut",
        });
        if (sp_falg) {
            hideGlobalNav(position);
        }
        return false;
    });
};
anchorScroll();


// loader letiables
let $window;
let $loader;
let $loaderProgress;
let loadingRequest;
let totalImgNum;
let loadedImgNum = 0;
let progressNowPosition = 0;

window.inmarks = window.inmarks || {};

// モーダル表示中
window.inmarks.IS_MODAL = false;

// ロード完了(loaderはフェードアウト前)
window.inmarks.LOAD_COMPLETE = "load_complete";

// loaderはフェードアウト後
window.inmarks.LOAD_OUT = "load_out";

/*------------------------------------------------*/
/*[LOADING]*/

let bk_stand_by = ['#stage', '.loaderInner'];

const setLoader = function () {
    $('body').css({ 'height': '100vh', 'overflow': 'hidden' });
    totalImgNum = $('img').length;
    totalImgNum = totalImgNum + bk_stand_by.length;
    updateLoader();

    $('img').each(function (i) {
        $(this).imagesLoaded(function () { loadedImgNum++; });
    });
    $.each(bk_stand_by, function (index, val) {
        $(val).imagesLoaded({ background: true }, function () {
            loadedImgNum++;
        });
    });
};

const updateLoader = function () {
    var progressPosition = (loadedImgNum / totalImgNum) * 100;
    progressNowPosition += (progressPosition - progressNowPosition) * 0.2;

    /**
     * ここに処理を記述
     */
    //=========================================
    if (progressNowPosition >= 99) {
        inmarks.CanselAnimationFrame(loadingRequest);
        loadComplete();
    }
    else {
        loadingRequest = inmarks.AnimationFrame(updateLoader);
    }
};

const loadComplete = function () {

    $loader.addClass('loaded');
    $(".loaded").on('animationend webkitAnimationEnd', function () {
        $loader.remove();
        $('body').css({ 'height': '', 'overflow': '' });
        $("#popup").trigger('click');
    });
};

/*------------------------------------------------*/
/*[LOADING]*/

const checkMedia = function () {
    sp_falg = matchMedia('(max-width: 768px)').matches;
    pc_falg = matchMedia('(min-width: 769px)').matches;
};

$(function () {
    $window = $(window);
    $loader = $('#loader');
    $loaderProgress = $loader.find('.loaderProgress');

    inmarks.AnimationFrame =
        Modernizr.prefixed("requestAnimationFrame", window) ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };

    inmarks.CanselAnimationFrame =
        Modernizr.prefixed("cancelAnimationFrame", window) ||
        function (callback) {
            window.clearTimeout(callback);
        };

    if ($loader.length > 0) setLoader();

    $window.on("load", function () {
        $window.trigger(inmarks.LOAD_COMPLETE);
    });

    checkMedia();
    $(window).on('resize', checkMedia());

    navInit();
    //$(window).on('resize', navInit());
    
    /*
    $('li[id^="cast_"]').animatedModal({
        animatedIn: 'bounceInUp',
        animatedOut: 'bounceOutDown',
        color: '#39BEB9',
        animationDuration: '.5s',
        beforeOpen: function () {

            var children = $(".thumb");
            var index = 0;

            function addClassNextChild() {
                if (index == children.length) return;
                children.eq(index++).show().velocity("transition.slideUpIn", { opacity: 1, stagger: 450, defaultDuration: 600 });
                window.setTimeout(addClassNextChild, 100);
            }

            addClassNextChild();

        },
        afterClose: function () {
            $(".thumb").hide();
            $('#animatedModal .player__wrap .active').removeClass('active');
            /*
            $('#animatedModal .player__wrap .modal_player').each(function (index, element) {
                gsap.to($(this),
                    {
                        yPercent: 10,
                        opacity: 0,
                    }
                );
            })
            */
    /*
}
});
*/
});